//This page acts as a HomePage.js 

import React from "react";
import { graphql } from "gatsby";
import AnyLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LogoSlider from '../components/slider/slider';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

// import homeBgImage from '../images/pages/home/banner.jpg';
// import hensImage from '../images/pages/home/hens.jpg';
// import stagImage from '../images/pages/home/stag.jpg';
// import storyBgImage from '../images/pages/home/story-bg.jpg';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import '../styles/styles.scss';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const HomePage = ({ data }) => {
  const { metaTitle, 
    heroBackgroundImage,
    heroText,
    brandsRightImage,
    brandsLeftImage,
    brandsLeftTitle,
    brandsRightTitle,
    brandsLeftParagraph,
    brandsRightParagraph,
    ourStoryBackgroundImage,
    ourStoryText
  } = data.contentfulHomePage;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero" style={{backgroundImage:`url(${heroBackgroundImage.file.url})`}}>
          <div className="hero-text-container align-self-center">
            {documentToReactComponents(heroText.json, options)}
            <AnyLink fade duration={0.6} className="main-button" to="/our-brands" value="See our brands">See our brands</AnyLink>
          </div>
        </div>
        <div className="our-brands">
          <Container>
            <h2>Our brands</h2>
          <Row className="row-eq-height">
            <Col md={6}>
              <img alt="Hen Heaven" src={brandsLeftImage.file.url} />
              <h3>{brandsLeftTitle}</h3>
              <p>{brandsLeftParagraph}</p>
            </Col>
            <Col md={6}>
              <img alt="Stag Company" src={brandsRightImage.file.url} />
              <h3>{brandsRightTitle}</h3>
              <p>{brandsRightParagraph}</p>
            </Col>
          </Row>
          </Container>
        </div>
        <div className="our-story-section" style={{backgroundImage:`url(${ourStoryBackgroundImage.file.url})`}}>
          <Container>
            <Row>
              <Col md={12}>
                {documentToReactComponents(ourStoryText.json, options)}
              <AnyLink fade duration={0.6} className="main-button" to="/about-us" value="Learn more">Learn More</AnyLink>
              </Col>
            </Row>
          </Container>
        </div>
        <LogoSlider />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    contentfulHomePage(slug: {eq: "home-page"}) {
      metaTitle
      pageTitle
      slug
      heroText {
        json
      }
      heroBackgroundImage {
        file {
          url
        }
      }
      brandsRightImage {
        file {
          url
        }
      }
      brandsLeftImage {
        file {
          url
        }
      }
      ourStoryBackgroundImage {
        file {
          url
        }
      }
      updatedAt
      brandsLeftTitle
      brandsRightTitle
      brandsLeftParagraph
      brandsRightParagraph
      ourStoryText {
        json
      }
    }
  }
`;